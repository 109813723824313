<template>
  <div v-loading="loading">
    <TaskLayout
      :title="$t('tasks.supplierTask.title')"
      :task="task"
      :actions="actions"
      @layout-loading="setLayoutLoading"
    >
      <template #task-snooze-modal-subtitle>
        <RestaurantSupplierSubtitle :supplier-name="supplier.name" :customer-name="currentTenant.name" />
      </template>
      <template #actions>
        <RequestsButtonContainer
          v-if="task && task.businessId"
          :source-business-id="task.businessId"
          :task-id="taskId"
          :target-businesses="[supplier.id, task.businessId]"
        />
      </template>
      <template #details>
        <div class="details-container p-6 mt-7">
          <div class="d-flex gap-1 align-items-center">
            <span class="subtitle-text">{{ currentTenant.name }}</span>
            <ArrowLeftIcon class="subtitle-icon" :fill="'#46494f'" />
            <span class="subtitle-text">{{ supplier.name }}</span>
          </div>
          <div class="details-row mt-4">
            <div class="detail">{{ $t('tasks.details.reconciliationInfo') }}</div>
            <Button
              type="link"
              class="text-typography-primary p-0 text-decoration-underline"
              @click="handleReconciliationDateClick"
            >
              {{ $t('tasks.details.reconciliation', { date: reconciliationDate }) }}
            </Button>
          </div>
          <div class="details-row mt-4">
            <div class="detail">{{ $t('tasks.details.comments') }}</div>
            <div>{{ taskComment }}</div>
          </div>
          <div class="details-row mt-4">
            <div class="detail">{{ $t('tasks.details.reconciliationSettings') }}</div>
            <div class="d-flex gap-2">
              <div v-if="reconciliationTemplate.note">
                <div v-if="reconciliationTemplate.note.length < 80">{{ reconciliationTemplate.note }}</div>
                <div v-else style="width: 552px">
                  <TruncatedText>{{ reconciliationTemplate.note }}</TruncatedText>
                </div>
              </div>
              <Button
                type="link"
                class="text-typography-primary p-0 text-decoration-underline"
                @click="openReconciliationTemplateModal"
                >{{ $t('tasks.details.showSettings') }}
              </Button>
            </div>
          </div>
        </div>
      </template>
      <template #content>
        <div class="mt-7">
          <span class="subtitle-text">{{ $t('tasks.supplierTask.content.actions') }}</span>
          <div class="mt-5 d-flex flex-column justify-content-between actions-items-container">
            <div
              class="actions-row"
              @mouseover="activeHoverRow = ACTIONS.NEW_CONTACT"
              @mouseleave="activeHoverRow = null"
            >
              <div
                class="d-flex justify-content-start actions-row__toggle-area"
                @mouseover="activeHoverToggleArea = ACTIONS.NEW_CONTACT"
                @mouseleave="activeHoverToggleArea = null"
                @click="handleActionStateChangeClick(ACTIONS.NEW_CONTACT)"
              >
                <div class="actions-row__icon-wrapper">
                  <div v-if="actions.newContact">
                    <div class="checked-background d-flex align-items-center justify-content-center">
                      <CheckXsIcon v-if="actions.newContact" :fill="checkFillColor" />
                    </div>
                  </div>
                  <div v-else>
                    <div
                      v-if="activeHoverToggleArea === ACTIONS.NEW_CONTACT"
                      class="unchecked-background-hover d-flex align-items-center justify-content-center"
                    >
                      <CheckXsIcon :fill="checkFillColor" />
                    </div>
                    <div v-else class="unchecked-background d-flex align-items-center justify-content-center">
                      <div class="inner-white-circle" />
                    </div>
                  </div>
                </div>

                <div
                  :class="actions.newContact ? 'line-through-text' : null"
                  class="text-nowrap actions-row__action-name"
                >
                  {{ $t('tasks.supplierTask.content.createNewContact') }}
                </div>
              </div>
              <div class="actions-row__details">
                <div v-if="!numberOfContacts">-</div>
                <div v-else>
                  <el-popover
                    placement="bottom"
                    trigger="click"
                    @show="showContactCard = true"
                    @close="showContactCard = false"
                  >
                    <ContactCard
                      v-if="showContactCard"
                      :user="contactUser"
                      :customer="currentTenant"
                      :supplier="supplier"
                      :token="token"
                    />
                    <template #reference>
                      <div v-if="numberOfContacts === 1" class="number-of-contacts">
                        {{ firstContactName }}
                      </div>
                      <div v-else class="number-of-contacts">
                        {{ $t('tasks.supplierTask.content.numberOfContacts', { number: numberOfContacts }) }}
                      </div>
                    </template>
                  </el-popover>
                </div>
              </div>

              <div v-if="activeHoverRow === ACTIONS.NEW_CONTACT">
                <div>
                  <div
                    class="text-typography-primary actions-row__call-to-action"
                    @mouseover="activeHoverCallToAction = ACTIONS.NEW_CONTACT"
                    @mouseleave="activeHoverCallToAction = null"
                    @click="openAddContactModal"
                  >
                    <PlusIcon
                      class="actions-row__call-to-action__btn"
                      :fill="activeHoverCallToAction === ACTIONS.NEW_CONTACT ? activeHoverColor : inActiveHoverColor"
                    />
                    <div
                      class="actions-row__call-to-action__txt"
                      :class="activeHoverCallToAction === ACTIONS.NEW_CONTACT ? 'hover-color' : null"
                    >
                      {{ $t('tasks.supplierTask.content.newContact') }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="actions-row"
              @mouseover="activeHoverRow = ACTIONS.EMAIL_INTRODUCTORY_CALL"
              @mouseleave="emailIntroductoryCall = null"
            >
              <div
                class="d-flex justify-content-start actions-row__toggle-area"
                @mouseover="activeHoverToggleArea = ACTIONS.EMAIL_INTRODUCTORY_CALL"
                @mouseleave="activeHoverToggleArea = null"
                @click="handleActionStateChangeClick(ACTIONS.EMAIL_INTRODUCTORY_CALL)"
              >
                <div class="actions-row__icon-wrapper">
                  <div v-if="actions.emailIntroductoryCall">
                    <div class="checked-background d-flex align-items-center justify-content-center">
                      <CheckXsIcon v-if="actions.emailIntroductoryCall" :fill="checkFillColor" />
                    </div>
                  </div>
                  <div v-else>
                    <div
                      v-if="activeHoverToggleArea === ACTIONS.EMAIL_INTRODUCTORY_CALL"
                      class="unchecked-background-hover d-flex align-items-center justify-content-center"
                    >
                      <CheckXsIcon :fill="checkFillColor" />
                    </div>
                    <div
                      v-else
                      class="unchecked-background d-flex align-items-center justify-content-center justify-content-center"
                    >
                      <div class="inner-white-circle" />
                    </div>
                  </div>
                </div>

                <div
                  :class="actions.emailIntroductoryCall ? 'line-through-text' : null"
                  class="text-nowrap actions-row__action-name"
                >
                  {{ $t('tasks.supplierTask.content.emailIntroductoryCall') }}
                </div>
              </div>
              <div class="actions-row__details">
                <div>-</div>
              </div>
            </div>

            <div
              class="actions-row"
              @mouseover="activeHoverRow = ACTIONS.RECONCILIATION_TEMPLATE"
              @mouseleave="activeHoverRow = null"
            >
              <div
                class="d-flex justify-content-start actions-row__toggle-area"
                @mouseover="activeHoverToggleArea = ACTIONS.RECONCILIATION_TEMPLATE"
                @mouseleave="activeHoverToggleArea = null"
                @click="handleActionStateChangeClick(ACTIONS.RECONCILIATION_TEMPLATE)"
              >
                <div class="actions-row__icon-wrapper">
                  <div v-if="actions.reconciliationTemplate">
                    <div class="checked-background d-flex align-items-center justify-content-center">
                      <CheckXsIcon v-if="actions.reconciliationTemplate" :fill="checkFillColor" />
                    </div>
                  </div>
                  <div v-else>
                    <div
                      v-if="activeHoverToggleArea === ACTIONS.RECONCILIATION_TEMPLATE"
                      class="unchecked-background-hover d-flex align-items-center justify-content-center"
                    >
                      <CheckXsIcon :fill="checkFillColor" />
                    </div>
                    <div v-else class="unchecked-background d-flex align-items-center justify-content-center">
                      <div class="inner-white-circle" />
                    </div>
                  </div>
                </div>

                <div
                  :class="actions.reconciliationTemplate ? 'line-through-text' : null"
                  class="text-nowrap actions-row__action-name"
                >
                  {{ $t('tasks.supplierTask.content.reconciliationTemplate') }}
                </div>
              </div>
              <div class="actions-row__details">
                <div>{{ reconciliationTemplatedDefinedText }}</div>
              </div>
              <div v-if="activeHoverRow === ACTIONS.RECONCILIATION_TEMPLATE">
                <div>
                  <div
                    class="text-typography-primary actions-row__call-to-action"
                    @mouseover="activeHoverCallToAction = ACTIONS.RECONCILIATION_TEMPLATE"
                    @mouseleave="activeHoverCallToAction = null"
                    @click="openReconciliationTemplateModal"
                  >
                    <SettingsIcon
                      class="actions-row__call-to-action__btn"
                      :fill="
                        activeHoverCallToAction === ACTIONS.RECONCILIATION_TEMPLATE
                          ? activeHoverColor
                          : inActiveHoverColor
                      "
                    />
                    <div
                      class="actions-row__call-to-action__txt"
                      :class="activeHoverCallToAction === ACTIONS.RECONCILIATION_TEMPLATE ? 'hover-color' : null"
                    >
                      {{ $t('commons.edit') }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="actions-row"
              @mouseover="activeHoverRow = ACTIONS.DEFINE_BACK_ACCOUNT"
              @mouseleave="activeHoverRow = null"
            >
              <div
                class="d-flex justify-content-start actions-row__toggle-area"
                @click="handleActionStateChangeClick(ACTIONS.DEFINE_BACK_ACCOUNT)"
              >
                <div class="actions-row__icon-wrapper">
                  <div v-if="actions.defineBankAccount">
                    <div class="checked-background d-flex align-items-center justify-content-center">
                      <CheckXsIcon v-if="actions.defineBankAccount" :fill="checkFillColor" />
                    </div>
                  </div>
                  <div v-else>
                    <div
                      v-if="activeHoverRow === ACTIONS.DEFINE_BACK_ACCOUNT"
                      class="unchecked-background-hover d-flex align-items-center justify-content-center"
                    >
                      <CheckXsIcon :fill="checkFillColor" />
                    </div>
                    <div v-else class="unchecked-background d-flex align-items-center justify-content-center">
                      <div class="inner-white-circle" />
                    </div>
                  </div>
                </div>

                <div
                  :class="actions.defineBankAccount ? 'line-through-text' : null"
                  class="text-nowrap actions-row__action-name"
                >
                  {{ $t('tasks.supplierTask.content.defineBankAccount') }}
                </div>
              </div>
              <div class="actions-row__details">
                <div v-if="bankAccounts[0]">
                  {{ bankAccountText }}
                </div>
                <div v-else>-</div>
              </div>
              <div v-if="activeHoverRow === ACTIONS.DEFINE_BACK_ACCOUNT">
                <div>
                  <div
                    class="text-typography-primary actions-row__call-to-action"
                    @mouseover="activeHoverCallToAction = ACTIONS.DEFINE_BACK_ACCOUNT"
                    @mouseleave="activeHoverCallToAction = null"
                    @click="openDefineBankAccountModal"
                  >
                    <SettingsIcon
                      class="actions-row__call-to-action__btn"
                      :fill="
                        activeHoverCallToAction === ACTIONS.DEFINE_BACK_ACCOUNT ? activeHoverColor : inActiveHoverColor
                      "
                    />
                    <div
                      class="actions-row__call-to-action__txt"
                      :class="activeHoverCallToAction === ACTIONS.DEFINE_BACK_ACCOUNT ? 'hover-color' : null"
                    >
                      {{ $t('commons.edit') }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </TaskLayout>

    <ReconciliationModal
      v-if="pickedReconciliationId"
      :reconciliation-id="pickedReconciliationId"
      @close="handleReconciliationModalClose"
      @open-chat="openChat"
    />

    <ReconciliationSettingsModal
      v-if="reconciliationTemplateParams"
      :supplier="supplier"
      :business="currentTenant"
      :reconciliation-template="reconciliationTemplateParams.template"
      @close="reconciliationTemplateParams = null"
      @save="(params) => handleReconciliationTemplateSave(params)"
    />

    <AddContactSetModal
      v-if="showAddContactModal"
      :supplier="supplier"
      :contact-sets="contactSets"
      @close="showAddContactModal = false"
      @open-create-contact-set-modal="openCreateContactSetModal"
    />
    <ContactSetEditModal
      v-if="showContactSetCreateModal"
      :clarity-business-id="clarityBusiness.id"
      :contact="{
        worksAt: [
          {
            id: supplier.id,
            name: supplier.name,
            type: BUSINESS_TYPES.supplier,
            worksWith: [{ id: currentTenant.id, name: currentTenant.name }],
            role: '',
          },
        ],
      }"
      @close="showContactSetCreateModal = false"
    />

    <SupplierForm
      v-if="dialogVisible"
      :visible.sync="dialogVisible"
      :starting-tab="0"
      :supplier-id="supplier.id"
      :supplier-company-id="supplier.number"
      :defaults="supplier"
      @close="handleSupplierFormClose"
    />
  </div>
</template>
<script>
import { isNil } from 'ramda';
import { DateTime } from 'luxon';
import { computed, ref, getCurrentInstance, watch } from 'vue';

import { ArrowLeftIcon, CheckXsIcon, SettingsIcon, PlusIcon } from '@/assets/icons';
import { Button, TruncatedText } from '@/modules/core';
import { useSupplier, SupplierForm, useBankAccounts } from '@/modules/suppliers';
import { ContactCard } from '@/modules/contact';
import { ContactSetEditModal, useContactSets } from '@/modules/contactSet';
import { useChatModal } from '@/modules/chatModal';
import { useTenancy } from '@/modules/auth';
import {
  ReconciliationModal,
  useReconciliationTemplate,
  useCreateReconciliationTemplate,
  useUpdateReconciliationTemplate,
  ReconciliationSettingsModal,
} from '@/modules/reconciliation';
import { options } from '@/locale/dateConfig';
import { BUSINESS_TYPES } from '@/modules/purchase-management/tools/constants';
import { useBusinesses } from '@/modules/businesses/compositions/businesses';

import { TaskLayout, RequestsButtonContainer, AddContactSetModal } from './components';
import { useLastReconciliation } from './composition';
import { RestaurantSupplierSubtitle } from '../components';
import { useTask, useActivityTrack } from '../compositions';

const ACTIONS = {
  NEW_CONTACT: 'newContact',
  EMAIL_INTRODUCTORY_CALL: 'emailIntroductoryCall',
  RECONCILIATION_TEMPLATE: 'reconciliationTemplate',
  DEFINE_BACK_ACCOUNT: 'defineBankAccount',
};

export default {
  components: {
    Button,
    TruncatedText,
    RestaurantSupplierSubtitle,
    SupplierForm,
    TaskLayout,
    ArrowLeftIcon,
    ReconciliationModal,
    CheckXsIcon,
    ContactCard,
    ReconciliationSettingsModal,
    SettingsIcon,
    PlusIcon,
    RequestsButtonContainer,
    AddContactSetModal,
    ContactSetEditModal,
  },
  setup() {
    const root = getCurrentInstance().proxy;
    const taskId = ref(root.$route.params.taskId);
    const { task, loading: taskLoading } = useTask(() => ({
      id: taskId.value,
    }));

    const { currentTenant, token, currentTenantId } = useTenancy();
    const { businesses } = useBusinesses(
      computed(() => ({
        first: 1,
        after: 0,
        capabilities: {
          serviceProvider: true,
        },
      }))
    );

    const clarityBusiness = computed(() => businesses.value.businesses.nodes[0]);

    useActivityTrack(taskId);

    const { reconciliation, loading: reconciliationLoading } = useLastReconciliation(
      computed(() => ({
        businessId: currentTenant.value.id,
        supplierId: task.value?.data?.supplierId,
      }))
    );

    watch(task, (newTask) => {
      if (newTask.businessId !== currentTenantId.value) {
        root.$router.push({ name: 'supplier-create-tasks' });
      }
    });

    const { supplier } = useSupplier(computed(() => task.value?.data?.supplierId));

    const { bankAccounts, refetch: bankAccountsRefetch } = useBankAccounts(computed(() => supplier.value?.id));

    const actions = {
      newContact: task?.value.data?.subTasks?.newContact ? true : false,
      emailIntroductoryCall: task?.value.data?.subTasks?.emailIntroductoryCall ? true : false,
      reconciliationTemplate: task?.value.data?.subTasks?.reconciliationTemplate ? true : false,
      defineBankAccount: task?.value.data?.subTasks?.defineBankAccount ? true : false,
    };

    const { contactSets } = useContactSets(
      computed(() => ({
        worksAt: task.value?.data?.supplierId,
        pagination: { limit: 50, offset: 0 },
      })),
      () => ({ enabled: !!task.value?.data?.supplierId })
    );

    const currentTenantContactSets = computed(() => {
      return contactSets.value.filter(({ worksAt }) =>
        worksAt.some((workplace) => workplace.worksWith.some((worksWith) => worksWith.id === currentTenant.value.id))
      );
    });

    const {
      reconciliationTemplate,
      loading: reconciliationTemplateLoading,
      refetch: reconciliationTemplateRefetch,
    } = useReconciliationTemplate(
      computed(() => ({
        businessId: currentTenant.value.id,
        supplierId: supplier.value.id,
      }))
    );

    const { mutate: createReconciliationTemplate } = useCreateReconciliationTemplate();
    const { mutate: updateReconciliationTemplate } = useUpdateReconciliationTemplate();

    const { closeChatModal, openChatModal } = useChatModal();

    const layoutLoading = ref(false);

    return {
      ACTIONS,
      BUSINESS_TYPES,
      showAddContactModal: ref(false),
      showContactSetCreateModal: ref(false),
      bankAccounts,
      bankAccountsRefetch,
      contactSets,
      currentTenantContactSets,
      layoutLoading,
      loading: computed(() => reconciliationLoading.value || taskLoading.value || layoutLoading.value),
      taskId,
      actions: ref(actions),
      task,
      reconciliation,
      currentTenant,
      supplier,
      pickedReconciliationId: ref(null),
      activeHoverRow: ref(null),
      activeHoverCallToAction: ref(null),
      activeHoverToggleArea: ref(null),
      contactPopoverVisible: ref(false),
      dialogVisible: ref(false),
      reconciliationTemplate,
      reconciliationTemplateParams: ref(null),
      isSupplierTemplateDefined: computed(() => !!reconciliationTemplate.value?.supplierId),
      createReconciliationTemplate,
      updateReconciliationTemplate,
      reconciliationTemplateLoading,
      reconciliationTemplateRefetch,
      closeChatModal,
      openChatModal,
      token,
      activeHoverColor: '#306feb',
      inActiveHoverColor: '#1F284D',
      checkFillColor: '#FFFFFF',
      contactUser: computed(() => ({
        type: 'supplier',
        payload: { worksAt: supplier.value.id, businessId: currentTenant.value.id },
      })),
      clarityBusiness,
      showContactCard: ref(false),
    };
  },
  computed: {
    firstContactName() {
      return this.currentTenantContactSets[0].firstName;
    },
    taskComment() {
      return this.task.data?.comment ?? '';
    },
    reconciliationDate() {
      const { periodStart, periodEnd } = this.reconciliation;
      if (!periodStart) return '';
      if (periodStart === periodEnd)
        return new Date(periodStart).toLocaleDateString(this.$i18n.locale, options.twoDigits);
      return this.formatDateShort(DateTime.fromISO(periodEnd).startOf('month'));
    },
    numberOfContacts() {
      return !isNil(this.currentTenantContactSets) && this.currentTenantContactSets.length > 0
        ? this.currentTenantContactSets.length
        : null;
    },
    reconciliationTemplatedDefinedText() {
      return this.isSupplierTemplateDefined ? this.$t('tasks.supplierTask.content.defined') : '-';
    },
    bankAccountText() {
      const { accountName, accountNumber, bankNumber, branchNumber, routingNumber } = this.bankAccounts[0];
      if (this.currentTenant.countryCode === 'IL') {
        return this.$t('tasks.supplierTask.content.bankAccountDetails', {
          bankNumber,
          accountNumber,
          branchNumber,
        });
      } else if (this.currentTenant.countryCode === 'US') {
        return this.$t('tasks.supplierTask.content.usBankAccountDetails', {
          routingNumber,
          accountName,
          accountNumber: accountNumber,
        });
      }
      return '';
    },
  },
  watch: {
    task(to) {
      this.actions = {
        newContact: to.data?.subTasks?.newContact ? true : false,
        emailIntroductoryCall: to.data?.subTasks?.emailIntroductoryCall ? true : false,
        reconciliationTemplate: to.data?.subTasks?.reconciliationTemplate ? true : false,
        defineBankAccount: to.data?.subTasks?.defineBankAccount ? true : false,
      };
    },
    $route() {
      const { taskId: newTaskId } = this.$route.params;
      this.taskId = newTaskId;
    },
  },

  methods: {
    handleSupplierFormClose() {
      this.dialogVisible = false;
      this.bankAccountsRefetch();
    },
    openDefineBankAccountModal() {
      this.dialogVisible = true;
    },

    openAddContactModal() {
      if (this.contactSets.length) this.showAddContactModal = true;
      else this.showContactSetCreateModal = true;
    },
    setLayoutLoading(bool) {
      if (bool) {
        this.closeChatModal();
      }
      this.layoutLoading = bool;
    },
    openChat() {
      const formattedPeriod =
        this.reconciliation.periodStart === this.reconciliation.periodEnd
          ? new Date(this.reconciliation.periodStart).toLocaleDateString(this.$i18n.locale, options.twoDigits)
          : DateTime.fromISO(this.reconciliation.periodStart).toLocaleString({
              month: 'short',
              year: 'numeric',
            });
      const title = `${this.$t('chat.billingManagement')} - ${formattedPeriod}`;
      this.openChatModal({
        supplier: this.supplier,
        title,
        formattedPeriod,
        reconciliationId: this.reconciliation.id,
        isChannelEnabled: !this.reconciliation.closed,
      });
    },

    formatDateShort(date) {
      return date
        ? new Date(date).toLocaleDateString(this.$i18n.locale, {
            month: 'short',
            year: 'numeric',
          })
        : '';
    },
    handleReconciliationModalClose() {
      this.pickedReconciliationId = null;
      this.closeChatModal();
    },
    handleReconciliationDateClick() {
      this.pickedReconciliationId = this.reconciliation.id;
    },
    handleActionStateChangeClick(key) {
      this.actions[key] = !this.actions[key];
    },

    openReconciliationTemplateModal() {
      this.reconciliationTemplateParams = {
        supplierId: this.supplier.id,
        template: this.reconciliationTemplate,
      };
    },
    async handleReconciliationTemplateSave(params) {
      this.isSupplierTemplateDefined
        ? await this.updateReconciliationTemplate(params)
        : await this.createReconciliationTemplate(params);
      await this.reconciliationTemplateRefetch();
      this.reconciliationTemplateParams = null;
    },
    openCreateContactSetModal() {
      this.showAddContactModal = false;
      this.showContactSetCreateModal = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.details-row {
  display: flex;
  .detail {
    width: 160px;
    min-width: 160px;
    color: $typography-secondary;
  }
}

.number-of-contacts {
  text-decoration: underline;
  &:hover {
    color: #306feb;
  }
}

.subtitle-text {
  font-weight: 700;
  font-size: 1rem;
  line-height: 20px;
}

.actions-items-container {
  height: 180px;
}

.subtitle-icon {
  width: 16px;
  height: 16px;
}

.details-container {
  border: 1px solid #d9dcde;
  border-radius: 4px;
}

.actions-row {
  height: 36px;
  padding: 0px 10px;
  cursor: pointer;

  display: flex;
  align-items: center;

  &:hover {
    background: #f3f3f4;
    border-radius: 4px;
  }
  &__toggle-area {
    min-width: 290px;
  }
  &__details {
    min-width: 300px;
  }

  &__icon-wrapper {
    width: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__action-name {
    margin: 0px 8px;
  }

  &__call-to-action {
    display: flex;
    &:hover {
      color: #306feb;
    }
    &__btn {
      max-width: 20px;
    }
    &__txt {
      text-decoration: underline;
      margin: 0px 4px;
    }
  }
}

.line-through-text {
  text-decoration: line-through;
}

.checked-background {
  background: #306feb;
  border-radius: 50%;
  width: 16px;
  height: 16px;
}

.hover-color {
  color: #306feb;
}

.unchecked-background {
  border-radius: 50%;
  max-width: 16px;
  height: 16px;
  &-hover {
    background: #bbbec2;
    border-radius: 50%;
    width: 16px;
    height: 16px;
  }
}
.inner-white-circle {
  background: white;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  border: 2px solid #d2d4d7;
}

:deep(input.el-input__inner) {
  height: 32px;
}
:deep(.el-input__prefix .search-icon) {
  margin-top: 8px;
}

:deep(i.el-icon-circle-close.el-input__clear) {
  margin-top: -2px;
  width: 1rem;
  height: 1rem;
}
</style>
